<template>
  <div class="max-content">
    <div class="order-wrap">
      <div class="header dfr cross-center fs14 fc000">
        <div class="title pointer" @click="goMeApp">My Application</div>
        <div class="line"></div>
        <div class="current-page ">Application Details</div>
      </div>
      <div class="progress">
        <order-progress
          v-if="detail"
          :detail="detail"
          @initpage="initDetail"
        ></order-progress>
      </div>
      <div class="content">
        <detail-card show :detail="detail"></detail-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: null,
      id: ""
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    this.initDetail();
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  },
  methods: {
    async initDetail() {
      const data = { order_id: this.id };
      const resp = await this.$API.post("orderDetail", data).then(resp => resp);
      if (resp.code == 1000) {
        this.detail = resp.data.info;
      }
    },
    goMeApp() {
      this.$back();
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.max-content {
  background: #fafafc;
  .order-wrap {
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;
    .header {
      padding-top: 21px;
      .title {
        transition: all 0.3s;
        &:hover {
          color: #87bcdc;
        }
      }
      .line {
        margin: 0 14px;
        width: 1px;
        height: 14px;
        background: #d8d8d8;
      }
      .current-page {
        color: #4e748b;
      }
    }
    .progress {
      min-height: 218px;
    }
    .content {
      padding-bottom: 20px;
    }
  }
}
</style>
